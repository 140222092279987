import request from '@/utils/request'
 
// 物品添加
export function addGoodsList(data) {
  return request('post', '/api/goods/addGoods', data)
}

 // 物品列表
 export function pageGoodsList(data) {
   return request('post', '/api/goods/goodsList', data)
 }

 // 物品修改
 export function updateGoodsList(data) {
   return request('post', '/api/goods/updateGoods', data)
 }

 // 物品删除
 export function deleteGoodsList(data) {
   return request('post', '/api/goods/deleteGoods', data)
 }