<template>
  <div id="goodslist">
    <!-- 头部 -->
    <header class="header">
      <div class="header-L">
        <!-- 模糊查询 -->
        <el-input
          v-model="kw"
          placeholder="查询（ 品牌 / 购买渠道 ）"
          @input="kwChange"
          class="mr20"
        />
        <!-- 品类筛选 -->
        <el-select
          v-model="selType"
          placeholder="请选择品类"
          @change="selTypeChange"
        >
          <el-option value="" label="全部品类"></el-option>
          <el-option
            v-for="item in optionsType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="header-R">
        <el-button type="primary" @click="handleAdd">新增物品</el-button>
      </div>
    </header>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      style="width: 100%"
      @row-click="rowClick"
    >
      <el-table-column prop="goodsName" label="物品名称" align="center" />
      <el-table-column
        prop="goodsType"
        label="品类"
        align="center"
        :formatter="changeType"
      />
      <el-table-column prop="goodsBrand" label="品牌" align="center" />
      <el-table-column prop="goodsPrice" label="价格" align="center" />
      <el-table-column prop="goodsSize" label="尺码" align="center" />
      <el-table-column
        prop="goodsColor"
        label="颜色"
        align="center"
        :formatter="changeColor"
      />
      <el-table-column prop="goodsUse" label="用途" align="center" />
      <el-table-column prop="goodsPoint" label="评价" align="center" />
      <el-table-column prop="goodsBuyWay" label="购买渠道" align="center" />
      <el-table-column prop="goodsLevel" label="推荐指数" align="center" />
      <el-table-column
        prop="goodsBuyDate"
        label="购买日期"
        align="center"
        :formatter="changeDate"
      />
      <el-table-column
        prop="goodsExpireDate"
        label="到期日期"
        align="center"
        :formatter="changeDate"
      />
      <el-table-column align="center" label="操作" width="200">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click.stop="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click.stop="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <footer>
      <el-pagination
        background
        :total="total"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </footer>
    <!-- 弹框 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      :title="formTitle"
      width="50%"
      custom-class="add-dialog"
    >
      <el-form :model="form" :disabled="formDisabled" inline>
        <!-- 物品名称 -->
        <el-form-item label="物品名称" :label-width="formLabelWidth">
          <el-input v-model="form.goodsName" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 品类 -->
        <el-form-item label="品类" :label-width="formLabelWidth">
          <el-select v-model="form.goodsType" placeholder="请选择品类">
            <el-option
              v-for="item in optionsType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 品牌 -->
        <el-form-item label="品牌" :label-width="formLabelWidth">
          <el-input v-model="form.goodsBrand" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 价格 -->
        <el-form-item label="价格" :label-width="formLabelWidth">
          <el-input v-model="form.goodsPrice" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 尺码 -->
        <el-form-item label="尺码" :label-width="formLabelWidth">
          <el-input v-model="form.goodsSize" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 颜色 -->
        <el-form-item label="颜色" :label-width="formLabelWidth">
          <el-select v-model="form.goodsColor" placeholder="请选择颜色">
            <el-option
              v-for="item in optionsColor"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 用途 -->
        <el-form-item label="用途" :label-width="formLabelWidth">
          <el-input v-model="form.goodsUse" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 评价 -->
        <el-form-item label="评价" :label-width="formLabelWidth">
          <el-input v-model="form.goodsPoint" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 购买渠道 -->
        <el-form-item label="购买渠道" :label-width="formLabelWidth">
          <el-input v-model="form.goodsBuyWay" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 推荐指数 -->
        <el-form-item label="推荐指数" :label-width="formLabelWidth">
          <el-input v-model="form.goodsLevel" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 购买日期 -->
        <el-form-item label="购买日期" :label-width="formLabelWidth">
          <el-date-picker
            v-model="form.goodsBuyDate"
            type="date"
            placeholder="请选择购买日期"
          >
          </el-date-picker>
        </el-form-item>
        <!-- 到期日期 -->
        <el-form-item label="到期日期" :label-width="formLabelWidth">
          <el-date-picker
            v-model="form.goodsExpireDate"
            type="date"
            placeholder="请设置到期日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="!formDisabled">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveList">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  pageGoodsList,
  addGoodsList,
  updateGoodsList,
  deleteGoodsList,
} from "@/api/home/goodslist";
import { formatterDate } from "@/utils/utils";
import {
  optionsType,
  optionsColor,
  changeTypeObj,
  changeColorObj,
} from "@/db/home/goodslist.js";
import { userNameList } from "@/api/setting/userlist";
export default {
  data() {
    return {
      // 表格
      tableData: [], //表格数据
      page: 1, //当前页
      total: 1, //总条数
      kw: "", //查询条件
      selType: "", //筛选品类
      // 弹窗
      dialogFormVisible: false, //弹框开关
      formTitle: "", //弹框标题
      formLabelWidth: "120px", //输入框宽度
      formDisabled: false, //表单禁用开关
      form: {
        goodsName: "", //品名
        goodsType: "", //品类
        goodsBrand: "", //品牌
        goodsPrice: "", //价格
        goodsSize: "", //尺码
        goodsColor: "", //颜色
        goodsUse: "", //用途
        goodsPoint: "", //评价
        goodsBuyWay: "", //购买渠道
        goodsLevel: "", //推荐指数
        goodsBuyDate: "", //购买日期
        goodsExpireDate: "", //到期日期
      },
      optionsType, // 品类选项
      optionsColor, // 颜色选项
      changeTypeObj, //品类转换
      changeColorObj, //颜色转换
      userNameListOption: [],
    };
  },
  mounted() {
    this.pageGoodsList();
    this.userNameList();
  },
  methods: {
    // 【转换】日期
    changeDate(row, column, cellValue) {
      return formatterDate(cellValue, "yyyy-MM-dd");
    },
    // 【转换】品类
    changeType(row, column, cellValue) {
      return this.changeTypeObj[cellValue];
    },
    // 【转换】颜色
    changeColor(row, column, cellValue) {
      return this.changeColorObj[cellValue];
    },
    // 【转换】名称

    // 【请求】分页任务列表
    pageGoodsList() {
      let data = {
        page: this.page,
        goodsType: this.selType, //品类筛选
        kw: this.kw,
      };
      pageGoodsList(data).then((res) => {
        this.tableData = res.data;
        this.total = res.total;
      });
    },

    // 【请求】用户下拉列表
    userNameList() {
      let _this = this;
      userNameList().then((res) => {
        _this.userNameListOption = res.data;
      });
    },

    // 【监听】分页点击事件
    handleCurrentChange(val) {
      this.page = val;
      this.pageGoodsList();
    },

    // 【监听】模糊查询
    kwChange() {
      this.pageGoodsList();
    },

    // 【监听】状态筛选
    selTypeChange() {
      this.pageGoodsList();
    },

    // 【监听】新增按钮
    handleAdd() {
      this.formTitle = "新增物品";
      this.dialogFormVisible = true;
      this.formDisabled = false;
      this.form = {
        goodsType: "", //品类
        goodsBrand: "", //品牌
        goodsPrice: "", //价格
        goodsSize: "", //尺码
        goodsColor: "", //颜色
        goodsUse: "", //用途
        goodsPoint: "", //评价
        goodsBuyWay: "", //购买渠道
        goodsLevel: "", //推荐指数
        goodsBuyDate: "", //购买日期
        goodsExpireDate: "", //到期日期
      };
    },

    // 【监听】行点击事件
    rowClick(row, column, event) {
      this.formTitle = "查看记录";
      this.dialogFormVisible = true;
      this.formDisabled = true;
      this.form = row;
    },

    // 【监听】编辑按钮
    handleEdit(index, row) {
      this.formTitle = "编辑记录";
      this.dialogFormVisible = true;
      this.formDisabled = false;
      this.form = row;
    },

    // 【监听】弹框表单提交
    saveList() {
      let data = this.form;
      // 编辑
      if (this.form.goodsId) {
        updateGoodsList(data).then((res) => {
          if (res.success) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
            this.pageGoodsList();
          }
        });
      }
      // 新增
      else {
        addGoodsList(data).then((res) => {
          if (res.success) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
            this.pageGoodsList();
          }
        });
      }
    },

    // 【监听删除】
    handleDelete(index, row) {
      let _this = this;
      this.$confirm("确定要删除该条任务吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            goodsId: row.goodsId,
          };
          deleteGoodsList(data).then((res) => {
            if (res.success) {
              _this.$message({
                message: res.msg,
                type: "success",
              });
              _this.dialogFormVisible = false;
              _this.pageGoodsList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#goodslist {
  padding: 24px;
  box-sizing: border-box;
}

.header {
  height: 0.6rem;
  padding: 0.1rem 0;
  display: flex;
  justify-content: space-between;
}

.header-L {
  display: flex;
}

footer {
  margin: 0.1rem 0;
}
</style>
