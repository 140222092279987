//品类转换
export const changeTypeObj = {
  1: "衣服",
  2: "裤子",
  3: "鞋子",
};
export const optionsType = [{
  value: 1,
  label: "衣服",
},
{
  value: 2,
  label: "裤子",
},
{
  value: 3,
  label: "鞋子",
}
];

// 颜色转换
export const changeColorObj = {
  1: "白色",
  2: "黑色",
  3: "红色",
};
export const optionsColor = [{
  value: 1,
  label: "白色",
},
{
  value: 2,
  label: "黑色",
},
{
  value: 3,
  label: "红色",
},
];